import React from 'react'
import Header from "../Header/Header";
import "./AboutPage.css"

const AboutPage = () => {
  return (
    <div>
      <Header
        title="About Us"
      />
    </div>
  );
}

export default AboutPage