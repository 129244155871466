import React from "react";
import "./Hero.css";
import Slider from "../Slider/Slider";
import HomeAbout from "../HomeAbout/HomeAbout";
import FeaturedServices from "../FeaturesServices/FeaturedServices";

const Hero = () => {
  return (
    <>
      <Slider />
    
    </>
  );
};

export default Hero;
