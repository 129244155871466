import React from 'react'
import Header from "../Header/Header";

const ServicesPage = () => {
  return (
    <div>
      <Header title="Our Services" />
    </div>
  );
}

export default ServicesPage